import { environment } from "src/environments/environment";

export class ClienteUtil {
  static MANSERV = "manserv";
  static TERNIUM = "ternium";
  static HMG2 = "hmg2";

  static isVale() {
    return environment.cliente == "vale";
  }

  static isBmsa() {
    return environment.cliente == "bmsa";
  }

  static isGerdau() {
    return environment.cliente == "gerdau";
  }

  static isValeNorte() {
    return environment.cliente == "valenorte";
  }

  static isHmg2() {
    return environment.cliente == "hmg2";
  }

  static isNxGold() {
    return environment.cliente == "nxgold";
  }

  static isCaraiba() {
    return environment.cliente == "caraiba";
  }

  static isDev() {
    return environment.cliente == "desenv";
  }

  static isAnglogold() {
    return environment.cliente == "anglogold";
  }
}
